<template>
  <div class="home">
    <Header/>

    <div class="carousel-container">
      <div class="carousel">
        <div
            class="carousel-item"
            v-for="(character, index) in characters"
            :key="index"
            :class="{ active: index === currentIndex }"
            :style="getCarouselItemStyle(index)"
        >
          <img :src="character.image" :alt="character.name" @error="handleImageError"/>
        </div>

        <div class="carousel-controls">
          <button class="arrow left-arrow" @click="prev">&#10094;</button>
          <button class="arrow right-arrow" @click="next">&#10095;</button>
        </div>
      </div>

      <div class="text-container">
        <h2 class="item-title">{{ characters[currentIndex].name }}</h2>
        <p class="item-description">{{ characters[currentIndex].description }}</p>
        <button class="action-button chat-button" @click="startChat">
          Chat With Me
        </button>
      </div>
    </div>
    <AuthPopup v-if="showOverlay" @authSuccess="onAuthSuccess"/>
    <footer class="footer">
      <nav>
        <a href="/about.html">About Us</a>
        <a href="/privacy.html">Privacy Policy </a>
        <a href="/user-agreement.html">User Agreement</a>
        <a href="/2257.html">2257</a>
        <a href="/contact-us.html">Contact Us</a>
        <a href="/complaints.html">Complaints</a>

      </nav>
      <p>© 2024 Nikki AI. All rights reserved.</p>
    </footer>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {useChatStore} from '@/stores/chat.js';
import Header from '@/components/HomeHeader.vue';
import AuthPopup from '@/components/SignInPopUp.vue';

const chatStore = useChatStore();
const currentIndex = ref(0);
const isAuthorized = ref(false);
const showOverlay = ref(false);
const characters = [
  {
    name: 'Nikki',
    image: '/images/56467421-af98-4062-8353-39223096d99d.webp',
    chid: "56467421-af98-4062-8353-39223096d99d",
    description: 'Hi, I’m all about passion and deep connections. Ready to dive into something unforgettable?',
  },
  {
    name: 'Imani',
    image: '/images/fb65922c-2128-47f5-a734-b3245bd48a93.webp',
    chid: "fb65922c-2128-47f5-a734-b3245bd48a93",
    description: 'Hi, I embrace my roots and love soulful conversations. How about we explore the wilderness together?',
  },
  // {
  //   name: 'Eva',
  //   image: '/images/01d47b6c-fe48-4750-902f-8f3786573d9a.webp',
  //   chid: "01d47b6c-fe48-4750-902f-8f3786573d9a",
  //   description: 'Hey, wanna be my Adam in this dark world?',
  // },
  {
    name: 'Nick',
    image: '/images/68626905-0c9e-4963-964f-16b4476bb0d8.webp',
    chid: "68626905-0c9e-4963-964f-16b4476bb0d8",
    description: 'Hey, I’m into tech and fitness. Want to work out or code together?',
  },
  // {
  //   name: 'Fernando',
  //   image: '/images/model5.webp',
  //   chid: "68b01cde-8318-4f6a-9eb3-dfd0a2f57c65",
  //   description: 'Hey, I’m Fernando. Spicy, fearless, and always down for a wild ride. You think you can keep up?',
  // },
  {
    name: 'Kimiko',
    image: '/images/d4c2fa4b-7b0f-4711-ae1b-3d2c637f51c1.webp',
    chid: "d4c2fa4b-7b0f-4711-ae1b-3d2c637f51c1",
    description: 'Hey, I’m all about thrilling, wild adventures. Ready to push the limits with me?',
  },
  {
    name: 'Alex',
    image: '/images/638db622-7693-47e5-8559-cfe8b5ff4a36.webp',
    chid: "638db622-7693-47e5-8559-cfe8b5ff4a36",
    description: 'Hey there, you seem interesting... care to dive into something a little exciting with me?',
  },
  {
    name: 'Erica',
    image: '/images/e59c2705-e02f-41cb-aee7-097f55dbcda6.webp',
    chid: "e59c2705-e02f-41cb-aee7-097f55dbcda6",
    description: 'Hey babe! Let’s dive into something unforgettable, yeah?',
  },
];
onMounted(async () => {

  const authStatus = sessionStorage.getItem('isAuthorized');
  if (authStatus === 'true') {
    isAuthorized.value = true;
  }
  await chatStore.loadCharacters();

});

function next() {
  currentIndex.value = (currentIndex.value + 1) % characters.length;
}

function prev() {
  currentIndex.value = (currentIndex.value - 1 + characters.length) % characters.length;
}

function onAuthSuccess() {
  isAuthorized.value = true;
  sessionStorage.setItem('isAuthorized', 'true');
  showOverlay.value = false;
  chatStore.setSelectedCharacter(characters[currentIndex.value]);
  goToChat();
}

function startChat() {
  if (!isAuthorized.value) {
    showOverlay.value = true;
    sessionStorage.setItem('selectedCharacter', JSON.stringify(characters[currentIndex.value]));
  } else {
    chatStore.setSelectedCharacter(characters[currentIndex.value]);
    goToChat();
  }
}


function goToChat() {
  window.location.href = '/chat.html';
}

function handleImageError(event) {
  event.target.src = '/images/model1.webp';
}

function getCarouselItemStyle(index) {
  const distance = (index - currentIndex.value + characters.length) % characters.length;
  const offset = 20;
  const itemCount = characters.length;
  const itemWidth = 100 / itemCount;

  if (distance === 0) {
    return {
      transform: `translateX(0%) scale(1.1)`,
      zIndex: '2',
      opacity: '1',
    };
  } else if (distance === -1 || distance === itemCount - 1) {
    return {
      transform: `translateX(-${itemWidth + offset}%) scale(0.8)`,
      opacity: '0.8',
    };
  } else if (distance === 1 || distance === -itemCount + 1) {
    return {
      transform: `translateX(${itemWidth + offset}%) scale(0.8)`,
      opacity: '0.8',
    };
  } else {
    return {
      transform: `translateX(${distance * itemWidth}%) scale(0.5)`,
      opacity: '0',
    };
  }
}
</script>

<style scoped src="./home.css"/>
